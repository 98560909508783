/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ButtonQuizExam from "../../../components/button/button-quiz-exam/ButtonQuizExam";
import PaginationBigButton from "../../../components/button/pagination-button/PaginationBigButton";
import PrimaryButtonPageLevel from "../../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../../components/button/special-button/PopupCloseButton";
import BackgroundImageFromUrl from "../../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../../components/parseImage/ImageFromUrl";
import { getAllAchievement } from "../../../config/achievement";
import { submitPracticeQuestionAnswers } from "../../../config/practiceQuestion";
import { getAnUnit, getListGradeUnits } from "../../../reducers/gradesSlice";
import {
  getQuizInformation,
  selectAnsweredQuestions,
  setQuestionAnswered,
  setQuestionState,
  setQuestionsAnswered,
  submitAnswers,
  createNewPractice,
  getAPracticeQuestions,
} from "../../../reducers/practiceQuestionSlice";
import "./UnitQuizLeftWrapper.style.css";

const UnitQuizLeftWrapper = ({
  questions,
  selectedIndex,
  handleQuestionChange,
  practiceQuizId,
  isPreview,
  setIsPreview,
  pagination,
  quiz,
  handleChangePagination,
  unitId,
  grades,
}) => {
  const [showPopupCancel, setShowPopupCancel] = useState(false);
  const [showPopupSubmit, setShowPopupSubmit] = useState(false);
  const [showFinishedQuiz, setShowFinishedQuiz] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [doneFirstTime, setdoneFirstTime] = useState(false);
  const [fullScoreTheFirstTimes, setFullScoreTheFirstTimes] = useState(false);
  const [allQuizFinshed, setAllQuizFinshed] = useState(false);
  const [achievements, setAchievements] = useState(null);
  var answeredQuestions = useSelector(selectAnsweredQuestions);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const token = cookies.get("signinUser");

  var checkStateAnswered = questions && questions.filter((item) => item.isAnswered === false);

  const handleChange = (val) => {
    handleQuestionChange(val);
    setShowPopupSubmit(false);
    // localStorage.removeItem('questionStateQuiz')
  };

  const checkingState = questions?.every((item) => item.question.showSolution === true);

  const clickShowPopupCancel = () => {
    setShowPopupCancel(true);

    if (checkingState) {
      cookies.remove("newPracticeId");
      cookies.remove("practiceQuizId");
      cookies.remove("practiceQuizIdLP");
      cookies.remove("quizId");

      navigate("/list-lesson");
    }
  };

  const clickShowPopupSubmit = () => {
    setToggle(!toggle);
    if (isPreview !== undefined) {
      if (toggle === false) {
        const result = JSON.parse(localStorage.getItem("questionStateQuiz"));
        localStorage.removeItem("questionStateQuiz");

        if (result) {
          dispatch(setQuestionState(result.questionState));
          dispatch(setQuestionAnswered(result.questionAnswered));
          dispatch(setQuestionsAnswered(result.questionsAnswered));
        }
        setIsPreview(true);
      } else {
        setIsPreview(false);
      }

      localStorage.removeItem("questionStateQuiz");
    } else {
      const result = JSON.parse(localStorage.getItem("questionStateQuiz"));
      if (result) {
        dispatch(setQuestionState(result.questionState));
        dispatch(setQuestionAnswered(result.questionAnswered));
        dispatch(setQuestionsAnswered(result.questionsAnswered));
        answeredQuestions = result.questionsAnswered;
      }
      setShowPopupSubmit(true);
      if (checkingState) {
        cookies.remove("newPracticeId");
        cookies.remove("practiceQuizId");
        cookies.remove("practiceQuizIdLP");
        localStorage.removeItem("questionStateQuiz");

        navigate("/list-lesson");
      }
    }
  };

  const comeBackListLesson = () => {
    cookies.remove("newPracticeId");
    cookies.remove("quizId");
    cookies.remove("practiceQuizId");
    localStorage.removeItem("targets");
    cookies.remove("practiceQuizIdLP");
    setFullScoreTheFirstTimes(false);

    localStorage.removeItem("doneFirstTime");
    localStorage.removeItem("questionStateQuiz");

    navigate("/list-lesson");
  };

  const handleCancel = () => {
    cookies.remove("newPracticeId");
    cookies.remove("quizId");
    cookies.remove("practiceQuizId");
    localStorage.removeItem("targets");
    cookies.remove("practiceQuizIdLP");
    const result = JSON.parse(localStorage.getItem("questionStateQuiz"));
    localStorage.removeItem("questionStateQuiz");

    if (result) {
      dispatch(setQuestionState(result.questionState));
      dispatch(setQuestionAnswered(result.questionAnswered));
      dispatch(setQuestionsAnswered(result.questionsAnswered));
      var submit = result.submit;
      dispatch(submitAnswers({ submit, token, practiceQuizId }));
    }
    navigate("/list-lesson");
  };

  const removePopup = () => {
    setShowPopupCancel(false);
    setShowPopupSubmit(false);
  };

  const handleSubmit = async () => {
    localStorage.removeItem("pagination");
    if (!checkingState) {
      if (grades === null) {
        dispatch(getListGradeUnits({ grades: cookies.get("gradeId") }));
      }

      findPracticeQuizIdLP();

      // if (quiz?.practiceQuizIdLP !== null) {
      //   localStorage.setItem('doneFirstTime', 'true')
      // }
      const resultQuiz = JSON.parse(localStorage.getItem("questionStateQuiz"));
      // handleChangePagination(1)

      if (resultQuiz) {
        dispatch(setQuestionState(resultQuiz.questionState));
        dispatch(setQuestionAnswered(resultQuiz.questionAnswered));
        dispatch(setQuestionsAnswered(resultQuiz.questionsAnswered));
        answeredQuestions = resultQuiz.questionsAnswered;
        handleQuestionChange(0,false);
      } else {
        answeredQuestions = questions.map((question) => ({
          questionId: question.questionId,
          questionIndex: question.questionIndex,
          answer: question.answer,
        }));
      }
      localStorage.removeItem("questionStateQuiz");
      localStorage.removeItem("targets");

      var payload = {
        submit: true,
        questions: answeredQuestions,
      };

      try {
        if (practiceQuizId !== null || cookies.get("practiceQuizId") || cookies.get("newPracticeId")) {
          practiceQuizId = cookies.get("newPracticeId") ? cookies.get("newPracticeId") : cookies.get("practiceQuizId");

          await submitPracticeQuestionAnswers(practiceQuizId, payload);
        }

        setShowFinishedQuiz(true);
        setIsRunning(true);
        setTimeout(() => {
          setShowLoading(false);
        }, 3000);
        let quizId = cookies.get("quizId");
        dispatch(
          getQuizInformation({
            quizId,
            token,
          })
        );

        const { result } = await getAllAchievement();

        if (achievements !== null && result?.achievements[0]?.count === achievements) {
          setFullScoreTheFirstTimes(false);
        } else if (
          achievements !== null &&
          result?.achievements[0]?.count > achievements &&
          localStorage.getItem("doneFirstTime")
        ) {
          setFullScoreTheFirstTimes(false);
          setdoneFirstTime(false);

          setAllQuizFinshed(true);
          localStorage.setItem("doneAllQuizFinshed", "true");
        } else if (
          achievements !== null &&
          result?.achievements[0]?.count > achievements &&
          !localStorage.getItem("doneFirstTime")
        ) {
          setFullScoreTheFirstTimes(false);
        }

        if (cookies.get("newPracticeId")) {
          cookies.remove("newPracticeId");
        } else if (cookies.get("practiceQuizId")) {
          cookies.remove("practiceQuizId");
        } else {
          cookies.remove("practiceQuizIdLP");
        }
      } catch (error) {
        console.log("err", error);
      }
    } else {
      if (cookies.get("newPracticeId")) {
        cookies.remove("newPracticeId");
      } else if (cookies.get("practiceQuizId")) {
        cookies.remove("practiceQuizId");
      } else {
        cookies.remove("practiceQuizIdLP");
      }

      navigate("/list-lesson");
    }
  };

  const renderScreenQuizFinished = () => {
    switch (currentIndex) {
      case 0:
        return (
          <>
            <div className="img__quiz">
              <ImageFromUrl objectId="admin/frontend-images/quiz/image-quiz.svg" alt="loading" />
            </div>
            <div>
              <p className="title__loading">Đang tính toán... </p>
              <span className="loader__quiz"></span>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="finished-notice">
              <ImageFromUrl objectId="admin/frontend-images/quiz/image-quiz.svg" alt="loading" />

              <p className="title-notice">Kết quả Về Đích</p>

              <div className="exam-score-card">
                <div className="group-exam">
                  <p>Số điểm của bạn</p>
                </div>
                <div className="exam-score">
                  <div className="mark-group">
                    <div className="score">
                      <p>{quiz?.correctAnswersLP !== null ? quiz?.correctAnswersLP : 0}</p>
                      <span>/{quiz?.generatedQuestionsLP !== null ? quiz?.generatedQuestionsLP : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <div className="finished-notice">
            <p className="title-notice">Kết quả {localStorage.getItem("unitName")}</p>

            <div className="exam-score-card">
              <div className="group-exam">
                <p>Số điểm của bạn</p>
              </div>
              <div className="exam-score">
                <div className="mark-group">
                  <div className="score">
                    <p>{quiz?.correctAnswersLP !== null ? quiz?.correctAnswersLP : 0}</p>
                    <span>/{quiz?.generatedQuestionsLP !== null ? quiz?.generatedQuestionsLP : 0}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="is-flex is-flex-direction-row" style={{ gap: "3.5rem" }}>
              {doneFirstTime && (
                <div className="group-score-exams" style={{ position: "relative" }}>
                  <div className="score" style={{ margin: " 0.5rem 0rem" }}>
                    <p>60</p>
                    <span>điểm</span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-23rem",
                      width: "100%",
                      left: "-0.5rem",
                    }}
                  >
                    <div className="image-success-exams">
                      <ImageFromUrl objectId="admin/frontend-images/exam/image-success-year-exams.svg" alt="" />
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "39.5rem",
                        left: "-3rem",
                        width: "20rem",
                        fontWeight: 400,
                      }}
                      className="has-text-kurio-main-color-white"
                    >
                      <p>
                        Đạt <b>100% </b> để <br /> nhận được thêm <b>40 điểm</b>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {fullScoreTheFirstTimes && (
                <div className="group-score-exams" style={{ position: "relative" }}>
                  <div className="score" style={{ margin: " 0.5rem 0rem" }}>
                    <p>40</p>
                    <span>điểm</span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-23rem",
                      width: "100%",
                      left: "-0.5rem",
                    }}
                  >
                    <div className="image-success-exams">
                      <ImageFromUrl objectId="admin/frontend-images/exam/image-success-year-exams.svg" alt="" />
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "39.5rem",
                        left: "-3rem",
                        width: "20rem",
                        fontWeight: 400,
                      }}
                      className="has-text-kurio-main-color-white"
                    >
                      <p>
                        Đạt <b>100% </b>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {allQuizFinshed && (
                <div className="group-score-exams" style={{ position: "relative" }}>
                  <div className="score" style={{ margin: " 0.5rem 0rem" }}>
                    <p>100</p>
                    <span>điểm</span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-23rem",
                      width: "100%",
                      left: "-0.5rem",
                    }}
                  >
                    <div className="image-success-exams">
                      <ImageFromUrl objectId="admin/frontend-images/exam/image-success-year-exams.svg" alt="" />
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "39.5rem",
                        left: "-3rem",
                        width: "20rem",
                        fontWeight: 400,
                      }}
                      className="has-text-kurio-main-color-white"
                    >
                      <p>
                        Đạt <b>100% </b>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="is-flex is-flex-direction-column" style={{ gap: "1.5rem" }}>
                <PrimaryButtonPageLevel
                  isDarkColor={true}
                  style={{ height: "4rem" }}
                  onClick={() => handleClickButton("LAM LAI")}
                >
                  Làm lại
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  isDarkColor={true}
                  style={{ height: "4rem" }}
                  onClick={() => {
                    handleClickButton("CHUONG TIEP");
                  }}
                >
                  Chương tiếp
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  isDarkColor={true}
                  style={{ height: "4rem" }}
                  onClick={() => {
                    handleClickButton("XEM LOI GIAI");
                  }}
                >
                  Xem lời giải
                </PrimaryButtonPageLevel>

                <p className="back-quiz" onClick={comeBackListLesson}>
                  Về chương
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  const findPracticeQuizIdLP = () => {
    const doneFirstTime = localStorage.getItem("doneFirstTime");

    if (!doneFirstTime && quiz?.practiceQuizIdLP === null) {
      setdoneFirstTime(true);

      localStorage.setItem("doneFirstTime", "true");
    }
  };

  // const findAllQuizFinshed = () => {
  //   const doneAllQuizFinshed = localStorage.getItem('doneAllQuizFinshed');

  //   if (!doneAllQuizFinshed && localStorage.getItem('doneFirstTime') && localStorage.getItem('daDatDiemToiDa')) {
  //     setdoneFirstTime(false)
  //     setFullScoreTheFirstTimes(false)

  //     setAllQuizFinshed(true)
  //     localStorage.setItem('doneAllQuizFinshed', 'true');
  //   }

  // }

  const handleClickButton = (action) => {
    // dispatch(setQuestionsAnswered([]));
    // dispatch(clearState([]));

    if (action === "LAM LAI") {
      if (quiz?._id) {
        dispatch(
          getQuizInformation({
            quizId: cookies.get("quizId"),
            token,
          })
        );

        dispatch(createNewPractice({ practiceId: quiz?._id, token }));
      } else {
        navigate("/list-lesson");
      }
      localStorage.removeItem("questionStateExams");
    } else if (action === "CHUONG TIEP") {
      localStorage.removeItem("doneFirstTime");
      localStorage.removeItem("doneAllQuizFinshed");

      let index = 0;

      for (var i = 0; i < grades?.length; i++) {
        if (grades[i]?._id === cookies.get("unitId")) {
          index = i;
        }
      }

      if (grades?.length > index + 1) {
        if (grades[index + 1]?.accessible === true) {
          dispatch(getAnUnit({ unitId: grades[index + 1]?._id }));
          cookies.set("unitId", grades[index + 1]?._id);
        }
      }
    } else if (action === "XEM LOI GIAI") {
      if (quiz?.practiceQuizIdLP) {
        cookies.set("practiceQuizIdLP", quiz?.practiceQuizIdLP);
        dispatch(
          getQuizInformation({
            quizId: cookies.get("quizId"),
            token,
          })
        );
        dispatch(getAPracticeQuestions({ practiceQuizIdLP: quiz?.practiceQuizIdLP, page: 1, size: 50 }));
      } else {
        navigate("/list-lesson");
      }
      localStorage.removeItem("questionStateQuiz");
    }

    setTimeout(() => {
      if (action === "XEM LOI GIAI") {
        if (quiz?.practiceQuizIdLP) {
          window.location.reload();
          navigate("/unit-quiz", {
            state: { prevRouter: "/list-lesson" },
          });
        } else {
          navigate("/list-lesson");
        }
      }

      if (action === "CHUONG TIEP") {
        if (cookies.get("unitId")) {
          navigate("/list-lesson", {
            state: { prevRouter: "/unit-quiz" },
          });
        } else {
          navigate("/list-lesson");
        }
      }

      if (action === "LAM LAI") {
        if (quiz?._id) {
          window.location.reload();
          navigate("/unit-quiz", {
            state: { prevRouter: "/list-lesson" },
          });
        } else {
          // navigate("/list-lesson");
        }
      }
    }, 500);
  };

  useEffect(() => {
    // kiemTraVaHienThiThanhTich()
    // findAllQuizFinshed()

    // if (quiz?.practiceQuizIdLP !== null) {
    //   localStorage.setItem('doneFirstTime', 'false')
    // }

    if (isRunning) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 3000);

      return () => clearTimeout(timer);
    }

    if (quiz?._id) {
      cookies.set("quizId", quiz?._id);
    } else {
      let quizId = cookies.get("quizId");
      if (!window.location.pathname.includes("preview")) {
        dispatch(
          getQuizInformation({
            quizId,
            token,
          })
        );
      }
    }

    const getAchievements = async () => {
      try {
        const { result } = await getAllAchievement();
        setAchievements(result?.achievements[0]?.count);
      } catch (error) {
        console.log(error);
      }
    };
    if(!window.location.pathname.includes("preview")) {
      getAchievements();
    }
  }, [dispatch, isRunning, currentIndex, quiz?._id, token, achievements]);

  return (
    <>
      {showPopupSubmit && !checkingState && (
        <div className="modal is-active " style={{ opacity: 1, zIndex: 2 }}>
          {/* <div
            className="modal-background"
            onClick={removePopup}
            style={{ opacity: 0.8 }}
          ></div> */}
          <div
            className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-background-kurio-main-color-white is-relative has-text-kurio-main-color-black"
            style={{
              padding: "8.4375rem 15.75rem",
              // borderRadius: "2rem",
              width: "100%",
              height: "100%",
              // width: "56.375rem",
              // height: "30.25rem",
              gap: "2rem",
            }}
          >
            <div className="is-flex is-flex-direction-column is-flex-gap-2 is-align-items-center">
              <p
                className="has-text-weight-bold"
                style={{
                  fontSize: "2.25rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                ! Nộp bài !
              </p>
              <div className="is-flex is-flex-direction-column is-justify-content-center is-flex-gap-4 is-align-items-center">
                <div
                  className="has-text-kurio-main-color-black has-text-weight-bold has-text-centered is-flex is-flex-gap-1"
                  style={{ fontSize: "1.5rem", lineHeight: "normal" }}
                >
                  <p>Đề thi</p>
                  <p className="is-uppercase">{localStorage.getItem("nameExam")}</p>
                </div>
                <p
                  className="has-text-weight-bold has-text-kurio-main-color-black is-uppercase has-background-kurio-main-color-light-gray"
                  style={{
                    fontSize: "0.75rem",
                    lineHeight: "normal",
                    padding: "0.5rem 0.75rem",
                    borderRadius: "1rem",
                  }}
                >
                  {localStorage.getItem("unitName") ? localStorage.getItem("unitName") : ""}
                </p>
              </div>
            </div>
            <div className="is-flex is-flex-direction-row is-align-items-center" style={{ gap: "5rem" }}>
              <div className="is-flex is-flex-direction-column is-flex-gap-5" style={{ width: "25.25rem" }}>
                <div className="is-flex is-align-items-center is-flex-gap-5">
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className=""
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        background: "#E5E3EB",
                      }}
                    ></p>
                    <p>Chưa làm</p>
                  </div>
                  <div
                    className=" is-flex is-align-items-center is-flex-gap-2"
                    style={{ fontSize: "0.875rem", lineHeight: "normal" }}
                  >
                    <p
                      className="has-background-kurio-main-color-yellow"
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    ></p>
                    <p>Đã làm</p>
                  </div>
                </div>

                <div className="columns m-0 is-multiline" style={{ gap: "0.75rem" }}>
                  {questions &&
                    questions.map((question, index) => {
                      return (
                        <div className={`column is-narrow m-0 p-0`} key={index}>
                          <PaginationBigButton
                            onClick={() => handleChange(index)}
                            IKMCMode={true}
                            key={index}
                            isClick={false}
                            id={question.questionId}
                            value={question.questionIndex}
                            isAnswered={question.isAnswered}
                            isCorrect={question.isCorrect}
                          >
                            {question.questionIndex + 1}
                          </PaginationBigButton>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="is-flex is-flex-direction-column is-flex-gap-6 is-align-items-flex-start" style={{}}>
                <div
                  className="has-text-weight-bold is-flex is-flex-direction-column is-flex-gap-2"
                  style={{
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  <p className="has-text-weight-medium">Tổng số câu hỏi: {questions?.length} câu</p>
                  <p>
                    Số câu đã làm: {questions?.length - checkStateAnswered?.length} / {questions?.length} câu.
                  </p>
                  <p className="has-text-kurio-red-main-accent">
                    Số câu chưa làm: {checkStateAnswered?.length} / {questions?.length} câu.
                  </p>
                  {/* <p>Bạn muốn tiếp tục làm bài?</p> */}
                </div>
                <div className="is-flex is-flex-direction-column" style={{ gap: "1rem" }}>
                  <PrimaryButtonPageLevel
                    onClick={handleSubmit}
                    style={{
                      height: "4rem",
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                  >
                    Xác Nhận Nộp bài
                  </PrimaryButtonPageLevel>
                  <SecondaryButtonLevelPage
                    style={{
                      borderRadius: "1rem",
                      maxWidth: "20rem",
                      width: "20rem",
                    }}
                    onClick={removePopup}
                  >
                    Chưa nộp
                  </SecondaryButtonLevelPage>
                </div>
              </div>
            </div>

            {/* <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div> */}
          </div>
        </div>
      )}

      {showFinishedQuiz && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/quiz/background-quiz.svg"
          className="modal is-active modal-background background-quiz"
          style={{ opacity: 1 }}
        >
          <div className="modal__quiz">{renderScreenQuizFinished()}</div>
        </BackgroundImageFromUrl>
      )}

      {showPopupCancel && !checkingState && (
        <div onClick={removePopup} className="modal is-active modal-background" style={{ opacity: 1 }}>
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Chờ đã !
            </h2>
            <p className="documnent-quiz">Bạn chưa nộp bài</p>

            <div className="is-flex is-flex-direction-column" style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel onClick={removePopup} style={{ height: "4rem" }}>
                Tiếp tục
              </PrimaryButtonPageLevel>
              <SecondaryButtonLevelPage onClick={handleCancel}>Thoát</SecondaryButtonLevelPage>

              <SecondaryButtonLevelPage onClick={handleSubmit}>Nộp Bài Ngay</SecondaryButtonLevelPage>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {/* {showPopupSubmit && !checkingState && (
        <div
          onClick={removePopup}
          className="modal is-active modal-background"
          style={{ opacity: 1 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              Bạn vẫn còn một số câu hỏi chưa được trả lời. <br /> Bạn có thật
              sự chắc chắn muốn nộp bài?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Hủy
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleSubmit}
                style={{ height: "4rem" }}
              >
                Nộp bài
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )} */}

      <div
        className="has-background-kurio-main-color-white is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between is-relative"
        style={{
          padding: "2.5rem 1.5rem 1.5rem 1.5rem",
          // height: "28.5rem",
          borderRadius: " 2.5rem 2.5rem 1.5rem 1.5rem",
          border: "1.5px solid #0A2A66",
          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          width: "16rem",
          gap: "2.5rem",
        }}
      >
        <div
          onClick={clickShowPopupCancel}
          className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-align-content-center"
          style={{
            padding: "0.5rem 0.75rem",
            background: "#E9EEF8",
            borderRadius: "1rem",
            gap: "0.75rem",
            height: "2.5rem",
            cursor: "pointer",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
            position: "absolute",
            zIndex: 1,
            top: "-1.25rem",
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M7.41425 6.00001L11.7072 1.70701C12.0982 1.31601 12.0982 0.684006 11.7072 0.293006C11.3162 -0.0979941 10.6842 -0.0979941 10.2933 0.293006L6.00025 4.58601L1.70725 0.293006C1.31625 -0.0979941 0.68425 -0.0979941 0.29325 0.293006C-0.09775 0.684006 -0.09775 1.31601 0.29325 1.70701L4.58625 6.00001L0.29325 10.293C-0.09775 10.684 -0.09775 11.316 0.29325 11.707C0.48825 11.902 0.74425 12 1.00025 12C1.25625 12 1.51225 11.902 1.70725 11.707L6.00025 7.41401L10.2933 11.707C10.4882 11.902 10.7443 12 11.0002 12C11.2562 12 11.5122 11.902 11.7072 11.707C12.0982 11.316 12.0982 10.684 11.7072 10.293L7.41425 6.00001Z"
              fill="#0A2A66"
            />
          </svg>
          <span
            style={{
              fontSize: "0.875rem",
              color: "#0a2a66",
              lineHeight: "normal",
              fontWeight: 700,
            }}
          >
            Thoát
          </span>
        </div>

        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ gap: "1rem", width: "11.5rem" }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: "0.9rem",
              lineHeight: "0.93rem",
              color: "#0A2A66",
              textAlign: "center",
            }}
          >
            {localStorage.getItem("unitName") ? `${localStorage.getItem("unitName")}` : ""}
          </p>

          <p
            style={{
              fontWeight: 700,
              fontSize: "1.3rem",
              lineHeight: "1.375rem",
              color: "#0A2A66",
            }}
          >
            Về Đích
          </p>
        </div>

        <div
          style={{
            width: "12rem",
          }}
        >
          <div
            className="columns m-0 is-multiline is-align-items-flex-start"
            style={{
              gap: "0.5rem",
              width: "11.5rem",
              // overflow: "auto",
              // padding:"2.5rem 1.5rem 1.5rem 1.5rem",
              // // height: "calc(100% - 20rem)",
              // height:"27rem"
            }}
          >
            {questions &&
              questions.map((question, index) => {
                return (
                  // <div
                  //   className={`${pagination === 1
                  //     ? index <= 19
                  //       ? ""
                  //       : "is-hidden"
                  //     : index <= 19
                  //       ? "is-hidden"
                  //       : ""
                  //     } column is-narrow m-0 p-0`}
                  //   key={index}
                  // >
                  <PaginationBigButton
                    onClick={() => handleChange(index)}
                    key={index}
                    IKMCMode={true}
                    isClick={index === selectedIndex ? true : false}
                    id={question.questionId}
                    value={question.questionIndex}
                    isAnswered={question.isAnswered}
                    isCorrect={question.isCorrect}
                  >
                    {question.questionIndex + 1}
                  </PaginationBigButton>
                  // </div>
                );
              })}
          </div>
        </div>
        <div className="is-flex is-flex-direction-column is-align-items-center is-flex-gap-4">
          {questions?.length > 20 && (
            <div
              className="is-flex is-flex-direction-row is-justify-content-center has-text-kurio-main-color-black is-uppercase has-text-weight-bold is-align-items-center is-align-content-center"
              style={{
                padding: "0.5rem 0.75rem",
                background: "#E9EEF8",
                borderRadius: "1rem",
                gap: "0.75rem",
                height: "2.5rem",
                cursor: "pointer",
                border: "1.5px solid #0A2A66",
                fontSize: "0.75rem",
                boxShadow: "0.125rem 0.125rem 0 0 #0A2A66",
              }}
              onClick={() => {
                handleChangePagination(pagination === 1 ? 2 : 1);
                localStorage.setItem("pagination", pagination === 1 ? 2 : 1);
              }}
            >
              {pagination === 1 && (
                <>
                  <p>Trang sau</p>
                  <img src="/images/button/icon-arrow.svg" alt="icon" />
                </>
              )}
              {pagination === 2 && (
                <>
                  <img style={{ transform: "scaleX(-1)" }} src="/images/button/icon-arrow.svg" alt="icon" />
                  <p>Trang trước</p>
                </>
              )}
            </div>
          )}

          {cookies.get("practiceQuizIdLP") ? (
            <div className="is-flex is-flex-direction-row is-flex-gap-2">
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#E5E3EB" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Chưa làm</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#4B9631" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đúng</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#E34D4D" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Sai</p>
              </div>
            </div>
          ) : (
            <div style={{ width: "105%" }} className="is-flex is-flex-direction-row is-flex-gap-2">
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#E5E3EB" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Chưa làm</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#FED131" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Đã làm</p>
              </div>
              <div className="is-flex is-flex-direction-row is-flex-gap-1 is-align-items-center">
                <span style={{ width: "0.75rem", height: "0.75rem", background: "#572CD1" }}></span>
                <p style={{ fontSize: "0.75rem", fontWeight: 500 }}>Hiện tại</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="is-relative is-flex is-align-items-center is-justify-content-center is-justify-content-space-between has-background-kurio-purple-light-1"
        style={{
          borderRadius: "1rem",
          width: "16rem",
          border: "1.5px solid #0A2A66",
          boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          height: "5rem",
          padding: "0.75rem",
        }}
      >
        <p
          className="has-text-kurio-main-color-white has-background-kurio-main-color-black has-text-weight-bold has-text-centered is-flex is-justify-content-center is-align-items-center"
          style={{
            fontSize: "0.875rem",
            lineHeight: "normal",
            width: "6rem",
            height: "3rem",
            borderRadius: "0.5rem",
          }}
        >
          Điểm lần trước
        </p>
        <div
          className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered"
          style={{
            fontSize: "2rem",
            lineHeight: "2.43rem",
          }}
        >
          <div className=" has-text-kurio-main-color-purple is-uppercase has-text-weight-bold has-text-centered ">
            {quiz?.correctAnswersLP !== null && quiz?.generatedQuestionsLP !== null ? (
              <div
                className="is-flex is-align-items-center is-flex-direction-row is-flex-gap-1 is-justify-content-center  has-background-kurio-purple-light-3"
                style={{
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                    textTransform: "uppercase",
                  }}
                >
                  {quiz?.correctAnswersLP !== null ? quiz?.correctAnswersLP : 0}
                </p>
                <p style={{ fontSize: "1.5rem" }}>/</p>
                <p
                  style={{
                    textTransform: "uppercase",
                    display: "flex",
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    lineHeight: "normal",
                  }}
                >
                  {quiz?.generatedQuestionsLP !== null ? quiz?.generatedQuestionsLP : 0}
                </p>
              </div>
            ) : (
              <div
                className="has-background-kurio-purple-light-3"
                style={{
                  marginRight: "0.25rem",
                  fontWeight: 700,
                  fontSize: "1.1rem",
                  lineHeight: "2.4rem",
                  textTransform: "uppercase",
                  width: "8.5rem",
                  height: "3rem",
                  borderRadius: "0.5rem",
                }}
              >
                <ImageFromUrl objectId="admin/frontend-images/hub/icon-point-exam.svg" alt="" />
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className=" "
        style={{
          // position: "absolute",
          height: "5rem",
          // width: "100%",
          margin: "0 center",
          // left: "2.3rem",
          // top: "40.7rem",
        }}
      >
        <ButtonQuizExam
          onClick={clickShowPopupSubmit}
          isClick={false}
          style={{
            maxWidth: "16rem",
            width: "16rem",
            padding: "1rem",
            borderRadius: "1rem",
            border: "1.5px solid #0A2A66",
            boxShadow: "0.125rem 0.25rem 0 0 #0A2A66",
          }}
        >
          {checkingState || isPreview ? "Đã xem xong" : "Nộp bài"}
        </ButtonQuizExam>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    quiz: state.practiceQuestion.quizInformation,
    questions: state.practiceQuestion.questions,
    seenQuestions: state.practiceQuestion.seenQuestions,
    grades: state.grades.grades,
    unitId: state.grades.unitId,
  };
}

export default connect(mapStateToProps)(UnitQuizLeftWrapper);
