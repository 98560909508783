import moment from "moment/moment";
import { getUserInfo } from "./auth";
import { getDailyLogin } from "./examsQuestion";
import { checkGooglePlayRenew } from "./payment";
// Hàm kiểm tra và gọi API daily-login

const checkAndCallDailyLoginAPI = () => {
  // Bước 1: Kiểm tra cache lastLoginDate
  const lastLoginDate = localStorage.getItem("lastLoginDate");
  if (lastLoginDate) {
    var systemTime = new Date(); // Lấy giờ hệ thống
    if (lastLoginDate === "null") {
      localStorage.removeItem("lastLoginDate");
      window.location.reload();
    } else {
      // kiểm tra lastLoginDate và systemTime có cùng 1 ngày không
      var systemTimeUnix = moment.unix(systemTime.getTime() / 1000);
      var lastLoginDateUnix = moment.unix(Number(lastLoginDate));
      if (!systemTimeUnix.isSame(lastLoginDateUnix, "day")) {
        localStorage.removeItem("lastLoginDate");
        window.location.reload();
      }
    }
  } else {
    callDailyLoginAPI(new Date());
  }
};

// Hàm gọi API daily-login
const callDailyLoginAPI = async (systemTime) => {
  try {
    if (!window.location.pathname.includes("error")) {
      if (!window.location.pathname.includes("preview")) {
        try {
          const { result } = await getDailyLogin();
          const { result: userInfo } = await getUserInfo();
          if (userInfo !== null && userInfo?.lastPayment?.platform === 1) {
            const now = new Date().getTime();
            const premiumFrom = new Date(userInfo?.premiumFrom).getTime();
            const premiumTo = new Date(userInfo?.premiumTo).getTime();
            if (
              (userInfo?.premiumFrom !== null &&
                userInfo?.premiumTo !== null &&
                premiumFrom <= now &&
                premiumTo > now) ||
              (userInfo?.premiumTo !== null && premiumTo > now)
            ) {
              checkGooglePlayRenew();
            }
          }
          const serverTimeUnix = Math.floor(new Date(result).getTime() / 1000); // Lấy giờ server time (UNIX timestamp)
          const systemTimeUnix = Math.floor(systemTime.getTime() / 1000); // Lấy giờ system time (UNIX timestamp)


          // Bước 4: So sánh giờ server time và giờ system time
          const timeDifference = Math.abs(serverTimeUnix - systemTimeUnix);
          const maxTimeDifference = 15 * 60; // 15 phút

          if (timeDifference > maxTimeDifference) {
            // Bước 5: Chuyển sang trang error-time nếu lệch quá 15 phút
            localStorage.setItem("lastLoginDate", serverTimeUnix);
            localStorage.setItem("showPopupDailyNow", true);
            // window.location.href = "error-server-time";
            return true;
          } else {
            // Bước 6: Lưu giờ server time mới vào cache
            localStorage.setItem("lastLoginDate", serverTimeUnix);
            localStorage.setItem("showPopupDailyNow", true);
            return false;
          }
        } catch (error) {}
      }
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
  }
};
export const checkServerTimeError = async () => {
  const result = await callDailyLoginAPI(new Date());
  return result;
};

export default checkAndCallDailyLoginAPI;
