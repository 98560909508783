import React, { useRef, useState } from "react";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import jsonParser from "../../../templates/helper/jsonParser";
import ButtonRegister from "./ButtonRegister";
import "./component.style.css";

const LandingPageUserReview = ({
  isMobile = false,
  isTablet = false,
  onClick,
}) => {
  const ListReviews = [
    {
      parentName: "ngọc",
      isMother: true,
      studentName: "huyền anh",
      content:
        "@@Từ ngày cho bé học với <b>Toán Kurio</b>, con có sự thay đổi về thái độ học tập rõ rệt. Con tập trung hơn, thích khám phá kiến thức Toán và đặc biệt là lúc nào cũng chủ động học!@@",
      imageUrl: "images/landing-page/card-image-1.png",
    },
    {
      parentName: "hiền",
      isMother: true,
      studentName: "thảo",
      content:
        "@@Nhờ có <b>Toán Kurio</b> mà bé nhà mình có thêm một người bạn mới đồng hành cùng học Toán. Bạn ấy vừa học vừa chơi và thích học Toán hơn!@@",
      imageUrl: "images/landing-page/card-image-2.jpg",
    },
    {
      parentName: "hiển",
      isMother: true,
      studentName: "quân",
      content:
        "@@<b>Kurio</b> đúng là cứu cánh cho con mình khi <b>luyện thi IKMC</b>! Chỉ cần ôn tập theo lộ trình, con đã tự tin vào <b>top 10% kỳ thi</b> mà không cần luyện thêm bên ngoài nữa. Ưng quá đi mất!@@",
      imageUrl: "images/landing-page/card-image-3.jpg",
    },
    {
      parentName: "hải",
      isMother: false,
      studentName: "cường",
      content:
        "@@Con mình tiến bộ vượt bậc từ khi học với <b>Kurio</b>, không còn học kiểu nhớ máy móc nữa mà hiểu rõ từng bài, từng dạng. Nhờ đó mà điểm thi cũng cao hơn.@@",
      imageUrl: "images/landing-page/card-image-4.png",
    },
    {
      parentName: "hà",
      isMother: true,
      studentName: "việt",
      content:
        "@@Từ lúc học với <b>Kurio</b>, con mình làm bài thi <b>IKMC</b> mà không gặp áp lực vì đã quá quen với các dạng toán tư duy. Giải thích cũng chi tiết, không chỉ học để thi mà còn hiểu bài sâu hơn.@@",
      imageUrl: "images/landing-page/card-image-5.png",
    },
    {
      parentName: "khánh",
      isMother: false,
      studentName: "vân",
      content:
        "@@Mình từng không tin con có thể yêu thích Toán, cho đến khi con bắt đầu học với <b>Kurio</b>. Bây giờ, tối nào con cũng cầm iPad để ngồi làm <b>Câu chuyện Toán học</b> của <b>Kurio!</b>@@",
      imageUrl: "images/landing-page/card-image-6.png",
    },
    {
      parentName: "nhien",
      isMother: true,
      studentName: "huy",
      content:
        "App Toán dễ sử dụng và dễ hiểu. Giá thành thì quá hợp lý. Bé nhà mình rất thích!",
      imageUrl: "images/landing-page/card-image-7.png",
    },
    {
      parentName: "hạnh",
      isMother: true,
      studentName: "nhung",
      content:
        "@@Lộ trình học cá nhân hóa của <b>Kurio</b> thật sự hiệu quả. Con mình học đúng phần cần bổ sung và tiến bộ rất nhanh. Rất đáng tiền và tiết kiệm thời gian!@@",
      imageUrl: "images/landing-page/card-image-8.png",
    },
    {
      parentName: "nguyên",
      isMother: false,
      studentName: "thảo",
      content:
        "@@Ấn tượng nhất là các video bài giảng của <b>Kurio</b>, ngắn gọn mà rất dễ hiểu. Con học mà không thấy nản chút nào, lộ trình mỗi ngày rõ ràng, chi tiết và có sẵn bài tập hôm nào làm gì. Quá tiện luôn!@@",
      imageUrl: "images/landing-page/card-image-9.png",
    },
    {
      parentName: "trang",
      isMother: true,
      studentName: "nam",
      content:
        "@@Mình đi làm bận rộn nhưng vẫn muốn con phát triển tư duy Toán học từ nhỏ. <b>Kurio</b> đã giúp mình chủ động được trong việc ôn tập của con, không cần đưa đón mà con vẫn ôn luyện đầy đủ.@@",
      imageUrl: "images/landing-page/card-image-10.png",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const handleChangeActiveIndex = (index) => {
    setActiveIndex(index);
  };

  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideToLoop(
        index < 0
          ? ListReviews.length - 1
          : index >= ListReviews.length
          ? 0
          : index
      );
    }
  };
  return (
    <div
      className={`${
        isMobile
          ? "is-flex-direction-column is-justify-content-center is-flex-gap-6"
          : isTablet
          ? "is-flex-direction-column is-flex-gap-6 is-justify-content-center"
          : "is-justify-content-space-between is-flex-gap-4 is-align-items-center"
      } has-background-kurio-main-color-white is-flex is-align-items-center max-width-landingpage review-landingpage`}
      style={{
        height: isMobile || isTablet ? "auto" : "40rem",
        width: "100%",
        padding: isMobile
          ? "1.5rem 2rem 4rem 2rem"
          : isTablet
          ? "4rem 0rem"
          : "0rem 5.5rem 0rem 4rem",
      }}
    >
      <div className="is-flex is-flex-direction-column is-flex-gap-6 has-text-kurio-main-color-purple">
        {!isTablet && (
          <div
            className={`
            is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-flex-start
             `}
          >
            <p
              className=""
              style={{
                fontSize: isMobile ? "4rem" : "5rem",
                lineHeight: "normal",
                fontStyle: "normal",
                fontWeight: 800,
              }}
            >
              30.000+
            </p>
            <p
              className={`${
                isMobile ? "is-size-6" : "is-size-5"
              } has-text-kurio-main-color-black`}
            >
              phụ huynh đã tin tưởng để<br></br>
              <b>KURIO</b> đồng hành
            </p>
            <p
              className="is-uppercase mt-2"
              style={{
                fontSize: isMobile ? "1.5rem" : "1.75rem",
                lineHeight: "normal",
                fontStyle: "normal",
                fontWeight: 800,
                color: "#9672FF",
              }}
            >
              NUÔI DƯỠNG{" "}
              <span className="has-text-kurio-main-color-purple">
                TÌNH YÊU<br></br> MÔN TOÁN
              </span>{" "}
              TRONG CON
            </p>
          </div>
        )}
        {isTablet && (
          <div className={`is-flex is-flex-direction-column`}>
            <div className="is-flex is-align-items-center">
              <p
                className=""
                style={{
                  fontSize: isTablet ? "4rem" : "5rem",
                  lineHeight: "normal",
                  fontStyle: "normal",
                  fontWeight: 800,
                }}
              >
                30.000+
              </p>
              <p
                className={`${
                  isMobile ? "is-size-6" : "is-size-5"
                } has-text-kurio-main-color-black`}
              >
                phụ huynh đã tin tưởng để<br></br>
                <b>KURIO</b> đồng hành
              </p>
            </div>
            <p
              className="is-uppercase mt-2"
              style={{
                fontSize: isTablet ? "1.5rem" : "1.75rem",
                lineHeight: "normal",
                fontStyle: "normal",
                fontWeight: 800,
                color: "#9672FF",
              }}
            >
              NUÔI DƯỠNG{" "}
              <span className="has-text-kurio-main-color-purple">
                TÌNH YÊU MÔN TOÁN
              </span>{" "}
              TRONG CON
            </p>
          </div>
        )}
        {!isMobile && !isTablet && (
          <ButtonRegister width="20rem" height="6rem" onClick={onClick} />
        )}
      </div>
      <div
        className={`${
          isMobile
            ? "is-flex is-flex-direction-column is-justify-content-center is-flex-gap-6"
            : "is-flex is-flex-direction-column"
        } slide-review-container`}
        style={{
          gap: "2rem",
          width: isMobile ? "20rem" : "42.5rem",
          height: isMobile ? "auto" : "28.6875rem",
        }}
      >
        <Swiper
          ref={swiperRef}
          className="slide-review is-flex-grow-0"
          spaceBetween={30}
          allowTouchMove={false}
          slidesPerView={isMobile ? 1 : 2}
          centeredSlides={false}
          autoplay={{ delay: 1500 }}
          pagination={{
            clickable: true,
          }}
          loop={true}
          modules={[FreeMode, Autoplay]}
          onActiveIndexChange={(swiper) => {
            handleChangeActiveIndex(swiper.realIndex);
          }}
          style={{ width: "100%" }}
        >
          {ListReviews.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  className="is-relative is-flex is-flex-direction-column is-justify-content-flex-end slide-review-item"
                  style={{
                    width: "20rem",
                    height: "25.1875rem",
                  }}
                >
                  <img
                    className="double-quote"
                    style={{
                      position: "absolute",
                      top: "0rem",
                      left: "2.5rem",
                      zIndex: 3,
                    }}
                    src="/images/landing-page/double-quote.svg"
                    alt=""
                  />
                  <div>
                    <div
                      className="is-flex is-relative is-align-items-center is-justify-content-center has-text-kurio-main-color-black is-size-6 review-content"
                      style={{
                        background: "#F1EEFC",
                        borderRadius: "4rem 4rem 0rem 4rem",
                        // padding: "2.5rem",
                        height: "18.8125rem",
                        zIndex: 1,
                      }}
                    >
                      <p
                        className="is-relative"
                        style={{ width: "15rem", height: "10.5rem", zIndex: 2 }}
                      >
                        {jsonParser(
                          item.content,
                          index,
                          "LandingPageUserReview"
                        )}
                      </p>
                      <img
                        style={{
                          position: "absolute",
                          bottom: "0rem",
                          right: "0rem",
                        }}
                        src="/images/landing-page/logomark.png"
                        alt=""
                      />
                    </div>
                    <div
                      className="is-flex is-relative is-align-items-flex-end is-justify-content-flex-end"
                      style={{ marginTop: "-1rem", zIndex: 4 }}
                    >
                      <div
                        className="is-flex is-relative is-align-items-center is-justify-content-center user-review-avatar"
                        style={{
                          background:
                            "linear-gradient(180deg, #CBA7EF 0%, #572CD1 100%)",
                          width: "6rem",
                          height: "6rem",
                          borderRadius: "99rem",
                          zIndex: 2,
                        }}
                      >
                        <img
                          style={{ width: "5.4rem", height: "5.4rem",objectFit:"cover", borderRadius: "99999rem" }}
                          src={item.imageUrl}
                          alt=""
                        />
                      </div>
                      <div
                        className="is-relative is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-center has-text-kurio-main-color-purple is-size-6 user-review"
                        style={{
                          marginLeft: "-2.5rem",
                          height: "5rem",
                          width: "17rem",
                          borderRadius: "0rem 0rem 2rem 0rem",
                          background: "#E0C6F9",
                          zIndex: 1,
                        }}
                      >
                        <p style={{ width: "12.75rem" }}>
                          {item.isMother ? "Chị" : "Anh"}{" "}
                          <b className="is-uppercase is-size-4">
                            {item.parentName}
                          </b>
                        </p>
                        <p style={{ width: "12.75rem" }}>
                          {item.isMother ? "Mẹ bé" : "Bố bé"}{" "}
                          <span className="is-capitalized">
                            {item.studentName}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className={`${
            isMobile ? "is-flex is-flex-gap-3" : "is-flex is-flex-gap-4"
          } slide-review is-align-items-center is-justify-content-center pagination-slide-review`}
          style={{ width: isMobile ? "20rem" : "42.5rem" }}
        >
          <div
            className="button-prev is-clickable is-flex is-align-items-center is-justify-content-center"
            onClick={() => {
              goToSlide(activeIndex - 1);
            }}
          >
            {" "}
          </div>
          {ListReviews.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (swiperRef.current && swiperRef.current.swiper) {
                    swiperRef.current.swiper.slideToLoop(index);
                  }
                }}
                className={
                  index === activeIndex
                    ? "swiper-pagination-bullet swiper-pagination-bullet-active is-clickable"
                    : "swiper-pagination-bullet is-clickable"
                }
              ></div>
            );
          })}
          <div
            className="button-next is-clickable is-flex is-align-items-center is-justify-content-center"
            onClick={() => {
              goToSlide(activeIndex + 1);
            }}
          ></div>
        </div>
        {isMobile && (
          <div className="btn-banner is-flex is-align-items-center is-justify-content-center">
            <ButtonRegister width="20rem" height="4rem" onClick={onClick} />
          </div>
        )}
      </div>
      {isTablet && (
        <ButtonRegister width="20rem" height="6rem" onClick={onClick} />
      )}
    </div>
  );
};

export default LandingPageUserReview;
