import Cookies from "universal-cookie";
import ConstantStrings from "../../constants/ConstantStrings";
import moment from "moment/moment";

const cookies = new Cookies();

export const checkAndGetAnonymousUserId = () => {
  var anonymousUserId = cookies.get("anonymousUser");
  if (anonymousUserId) {
    return anonymousUserId;
  } else {
    let uuid = window.crypto.randomUUID();
    anonymousUserId = uuid.split("-").join("").substring(0, 24);

    cookies.set("anonymousUser", anonymousUserId, {
      path: "/",
      maxAge: 24 * 60 * 60,
    });

    return anonymousUserId;
  }
};

export const checkRouteCategoryRoadMap = (router) => {
  switch (router) {
    case "/roadmap-exam":
      return ConstantStrings.IKMC_ID;
    case "/exam-preparation-HK1":
      return ConstantStrings.HK1_ID;
    case "/exam-preparation-HK2":
      return ConstantStrings.HK2_ID;

    default:
      return ConstantStrings.IKMC_ID;
  }
};

export const USER_IKMC_TYPE = 2;
export const USER_TEST_PREP_TYPE = 3;
export const SCREEN_PRACTICE = "PRACTICE";
export const SCREEN_IKMC = "IKMC";
export const SCREEN_UPGRADE = "UPGRADE";

// export const checkPremium = (userInfo,isShowSolution = false) => {
//   var result = false;
//   if (
//     userInfo !== null &&
//     userInfo?.premiumFrom &&
//     userInfo?.premiumTo &&
//     new Date(userInfo?.premiumFrom).getTime() <= new Date().getTime() &&
//     new Date(userInfo?.premiumTo).getTime() > new Date().getTime()
//   ) {
//     result = true;
//   } else if (userInfo?.studentType?.type === USER_IKMC_TYPE) {
//     if (isShowSolution) {
//       result = false;
//     }else{
//       result = true;
//     }
//   } else if (userInfo?.premiumFrom === null || userInfo?.premiumTo === null) {
//     result = false;
//   }
//   return result;
// };

export const checkPremium = ({
  userInfo,
  isShowSolution = false,
  screen = "other",
}) => {
  let result = false;

  if (
    userInfo !== null &&
    ((userInfo?.premiumFrom &&
      userInfo?.premiumTo &&
      moment(userInfo?.premiumFrom).isSameOrBefore(moment()) &&
      moment(userInfo?.premiumTo).isAfter(moment())) ||
      (userInfo?.premiumTo && moment(userInfo?.premiumTo).isAfter(moment())))
  ) {
    if (
      userInfo?.studentType?.type === USER_TEST_PREP_TYPE &&
      screen !== SCREEN_PRACTICE &&
      screen !== SCREEN_UPGRADE &&
      screen !== SCREEN_IKMC
    ) {
      result = false;
    } else {
      result = true;
    }
  } else if (
    userInfo?.studentType?.type === USER_IKMC_TYPE ||
    userInfo?.studentType?.type === USER_TEST_PREP_TYPE
  ) {
    if (SCREEN_IKMC === screen) {
      result = !isShowSolution; // Nếu isShowSolution là true thì trả về false
    } else {
      return false;
    }
  } else if (
    (userInfo?.premiumFrom === null && userInfo?.premiumTo === null) ||
    userInfo?.premiumTo === null
  ) {
    result = false;
  }

  return result;
};
