import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ScrollRefWrapper from "../../detail-components/ScrollRefWrapper";
import "./assets/css/main.css";
import ButtonRegister from "./components/ButtonRegister";
import LandingPageBanner from "./components/LandingPageBanner";
import LandingPageFeature from "./components/LandingPageFeature";
import LandingPageForm from "./components/LandingPageForm";
import { default as LandingPageIntro } from "./components/LandingPageIntro";
import LandingPageQA from "./components/LandingPageQA";
import LandingPageUserReview from "./components/LandingPageUserReview";

// var colorTextH1 = "#572CD1";
var colorWhite = "#FAFBFC";
var colorBlack = "#0A2A66";

const ResizeLandingPage = () => {
  const scrollableDivRef = useRef(null);
  const divRef = useRef(null);
  const [isHeaderFixed, setHeaderFixed] = useState(null);
  const [isHeaderFixedMobile, setHeaderFixedMobile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const header = document.getElementById("header-landingpage");
    const banner = document.getElementById("banner-landingpage");
    const headerTablet = document.getElementById("tablet-header");
    const bannerTablet = document.getElementById("tablet-banner");

    const scrollableDiv = scrollableDivRef.current;

    if (header && banner) {
      const headerHeight = header.offsetHeight;
      const divBanner = banner.getBoundingClientRect().top;
      if (divBanner <= headerHeight) {
        if (divBanner < 0 && divBanner <= headerHeight) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(false);
        }
      }
    }
    if (scrollableDiv || (headerTablet && bannerTablet)) {
      const headerTabletHeight = headerTablet?.offsetHeight;
      const divTabletBanner = bannerTablet?.getBoundingClientRect().top;
      if (divTabletBanner <= headerTabletHeight) {
        if (divTabletBanner < 0 && divTabletBanner <= headerTabletHeight) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(false);
        }
      }
      if (scrollableDiv) {
        if (scrollableDiv.getBoundingClientRect().top <= -8) {
          setHeaderFixedMobile(true);
        } else {
          setHeaderFixedMobile(false);
        }
      }
    }
  };
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };
  const handleDownloadApp = () => {
    var mobileOS = getMobileOperatingSystem();

    if (mobileOS === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=vn.kurio.android";
    } else if (mobileOS === "iOS") {
      window.location.href =
        "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573";
    } else {
      window.location.href = "/register-mobile";
    }
  };
  const handleScrollToForm = () => {
    const formRegister = document.getElementById("form-register");
    const offsetTop = formRegister.offsetTop - 5 * 16;
    window.scrollTo({ top: offsetTop, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const scrollableDiv = scrollableDivRef.current;
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        scrollableDiv.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    } else {
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [windowWidth]);
  return (
    <>
      <ScrollRefWrapper />
      <div
        className="wrapper-content has-background-kurio-main-color-white"
        ref={divRef}
      >
        {/* Desktop landing page */}
        {windowWidth > 1100 && (
          <>
            <div
              className=" has-background-kurio-main-color-white"
              id="dekstop-landingpage"
            >
              <div
                id="dekstop-landingpage"
                style={{
                  width: "100%",
                  height: "6rem",
                  padding: "0rem 2.5rem",
                }}
                className={
                  "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center desktop-header"
                }
              >
                <img
                  style={{ width: "9.27144rem", height: "3rem" }}
                  src="/images/landing-page/logo.svg"
                  alt=""
                />
                <div
                  className="is-flex is-align-items-center is-justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <button
                    onClick={() => {
                      window.location.href = "/signin";
                    }}
                    className=" has-text-weight-bold is-clickable is-uppercase"
                    style={{
                      color: "#8864F0",
                      padding: "1rem 2rem",
                      fontSize: "1.5rem",
                      border: "none",
                      borderRadius: "2rem",
                      background: "#E6DFFE",
                      height: "4rem",
                    }}
                  >
                    Đăng nhập
                  </button>
                  <ButtonRegister
                    onClick={() => (window.location.href = "/register")}
                  />
                </div>
              </div>
              <section
                id="header-landingpage"
                className={`${
                  isHeaderFixed === null
                    ? "displayNone"
                    : isHeaderFixed
                    ? "fixedHeader"
                    : "remote-fixed-header"
                } has-background-kurio-main-color-white`}
                style={{ width: "100%", zIndex: 99999 }}
              >
                <header
                  style={{
                    width: "100%",
                    height: "6rem",
                    padding: "0rem 2.5rem",
                  }}
                  className={
                    "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
                  }
                >
                  <img
                    style={{ width: "9.27144rem", height: "3rem" }}
                    src="/images/landing-page/logo.svg"
                    alt=""
                  />

                  <div
                    className="is-flex is-align-items-center is-justify-content-center"
                    style={{ gap: "10px" }}
                  >
                    <button
                      onClick={() => {
                        window.location.href = "/signin";
                      }}
                      className=" has-text-weight-bold is-clickable is-uppercase"
                      style={{
                        color: "#8864F0",
                        padding: "1rem 2rem",
                        fontSize: "1.5rem",
                        border: "none",
                        borderRadius: "2rem",
                        background: "#E6DFFE",
                        height: "4rem",
                      }}
                    >
                      Đăng nhập
                    </button>
                    <ButtonRegister
                      onClick={() => (window.location.href = "/register")}
                    />
                  </div>
                </header>
              </section>
              <div id="banner-landingpage" style={{ width: "100%" }}>
                <LandingPageBanner
                  onClick={() => (window.location.href = "/register")}
                  handleDownloadApp={handleDownloadApp}
                />
              </div>
              <LandingPageIntro />
              <LandingPageFeature />
              <LandingPageUserReview
                onClick={() => (window.location.href = "/register")}
              />
              <LandingPageQA />
              <div
                id="form-register"
                className="is-flex is-relative is-align-items-flex-start is-justify-content-center"
                style={{
                  background: "#F1EEFC",
                  backgroundImage:
                    "url(/images/landing-page/background-uu-dai.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  minHeight: "42.5rem",
                  zIndex: 9999,
                }}
              >
                <div
                  className="max-width-landingpage is-flex is-align-items-flex-end is-justify-content-center is-flex-grow-1"
                  style={{ height: "100%", minHeight: "42.5rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-align-items-center"
                    style={{}}
                  >
                    <img
                      className="pt-3"
                      src="/images/landing-page/desktop-uu-dai.png"
                      alt=""
                      style={{ width: "22.95349rem" }}
                    />
                    <img
                      src="/images/landing-page/image-landing-page-form.png"
                      alt=""
                      style={{ height: "22rem", objectFit: "contain" }}
                    />
                  </div>
                  <div
                    className="is-flex is-align-items-center is-justify-content-center"
                    style={{ height: "100%", minHeight: "42.5rem" }}
                  >
                    <LandingPageForm />
                  </div>
                </div>
              </div>
              <section
                id="max-width-landingpage"
                className="max-width-landingpage"
                style={{ background: colorWhite, width: "100%" }}
              >
                <footer
                  className="is-flex is-align-items-start"
                  style={{ gap: "4rem", padding: "4rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                    style={{ gap: "1.5rem", width: "20rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      Liên hệ
                    </p>
                    <div
                      className="is-flex is-flex-direction-column"
                      style={{ gap: "1rem" }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1.25rem",
                          color: colorBlack,
                          textTransform: "uppercase",
                        }}
                      >
                        CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
                      </p>

                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "1.125rem",
                        }}
                      >
                        Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở
                        Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.
                      </span>
                    </div>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1rem" }}
                    >
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center"
                        style={{ gap: "1rem" }}
                      >
                        <img src="/images/landing-page/icon-gmail.svg" alt="" />
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          contact@ikemso.com
                        </p>
                      </div>
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center"
                        style={{ gap: "1rem" }}
                      >
                        <img src="/images/landing-page/icon-phone.svg" alt="" />
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          0971 500 120
                        </p>
                      </div>
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center"
                        style={{ gap: "1rem" }}
                      >
                        <img src="/images/landing-page/icon-zalo.png" alt="" />
                        <p
                          style={{
                            fontWeight: 700,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          0971 500 120
                        </p>
                      </div>
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center"
                        style={{ gap: "1rem" }}
                      >
                        <img
                          src="/images/landing-page/icon-address.svg"
                          alt=""
                        />
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "1.5rem",
                          }}
                        >
                          Số 128, phố Nguyễn Thái Học, phường Điện Biên, quận Ba
                          Đình, Hà Nội
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                    style={{ gap: "1.5rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      Chính sách
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1rem" }}
                    >
                      <Link to={"/payment-methods"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Hình thức thanh toán
                        </p>
                      </Link>
                      <Link to={"/return-policy"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Vận chuyển - Trả hàng hoàn tiền
                        </p>
                      </Link>
                      <Link to={"/privacy-policy"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Chính sách bảo mật
                        </p>
                      </Link>
                    </div>

                    <Link to={"/payment-policy"}>
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                        style={{
                          gap: "0.75rem",
                          width: "15rem",
                          height: "3.5rem",
                          borderRadius: "1rem",
                          border: "1px solid #0A2A66",
                          background: colorWhite,
                          boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px",
                        }}
                      >
                        <img
                          style={{ width: "6.25rem", height: "1.89581rem" }}
                          src="/images/landing-page/image-vnpay.png"
                          alt=""
                        />
                        <p
                          style={{
                            width: "5.125rem",
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Chính sách thanh toán
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "3rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1.5rem" }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1.25rem",
                          color: colorBlack,
                          textTransform: "uppercase",
                        }}
                      >
                        Ứng dụng
                      </p>

                      <div
                        className="is-flex is-flex-direction-column is-align-items-flex-start"
                        style={{ gap: "1rem" }}
                      >
                        <Link
                          to={
                            "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
                          }
                        >
                          <img
                            src="/images/landing-page/app-store.png"
                            alt=""
                          />
                        </Link>
                        <Link
                          to={
                            "https://play.google.com/store/apps/details?id=vn.kurio.android"
                          }
                        >
                          <img src="/images/landing-page/gg-play.png" alt="" />
                        </Link>
                      </div>
                    </div>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1.5rem" }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1.25rem",
                          color: colorBlack,
                          textTransform: "uppercase",
                        }}
                      >
                        Mạng xã hội
                      </p>

                      <div
                        className="is-flex is-flex-direction-column is-align-items-flex-start"
                        style={{ gap: "1rem" }}
                      >
                        <Link to={"https://www.facebook.com/kurio.vn"}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: "1rem",
                              color: colorBlack,
                              lineHeight: "normal",
                            }}
                          >
                            Facebook
                          </p>
                        </Link>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Youtube
                        </p>
                        <Link to={"https://zalo.me/0971500120"}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: "1rem",
                              color: colorBlack,
                              lineHeight: "normal",
                            }}
                          >
                            Zalo
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ height: "24.125rem" }}
                    className="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-center"
                  >
                    <Link
                      to={
                        "http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1"
                      }
                    >
                      <img
                        style={{ width: "12.5rem", height: "4.75113rem" }}
                        src="/images/landing-page/image-bct.png"
                        alt=""
                      />
                    </Link>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                      style={{ gap: "1.5rem" }}
                    >
                      <img
                        onClick={() => backToTop()}
                        style={{
                          width: "5rem",
                          height: "5rem",
                          cursor: "pointer",
                        }}
                        src="/images/landing-page/icon-back-to-top.svg"
                        alt=""
                      />

                      <p
                        onClick={() => backToTop()}
                        style={{
                          fontSize: "1.25rem",
                          color: colorBlack,
                          cursor: "pointer",
                          textTransform: "uppercase",
                          fontWeight: 700,
                          lineHeight: "normal",
                          width: "6rem",
                        }}
                      >
                        Về đầu trang
                      </p>
                    </div>
                  </div>
                </footer>
              </section>
            </div>
          </>
        )}

        {/* Tablet landing page */}
        {windowWidth >= 768 && windowWidth <= 1100 && (
          <div
            className="container-landingpage has-background-kurio-main-color-white"
            id="tablet-landingpage"
          >
            <header
              style={{ width: "100%", height: "6rem", padding: "0rem 3rem" }}
              className={
                "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
              }
            >
              <img
                style={{ width: "9.27144rem", height: "3rem" }}
                src="/images/landing-page/logo.svg"
                alt=""
              />
              <div
                className="is-flex is-align-items-center is-justify-content-center"
                style={{ gap: "10px" }}
              >
                <button
                  onClick={() => {
                    window.location.href = "/signin";
                  }}
                  className=" has-text-weight-bold is-clickable is-uppercase"
                  style={{
                    color: "#8864F0",
                    padding: windowWidth <= 820 ? "0.5rem 1rem" : "1rem 2rem",
                    fontSize: windowWidth <= 820 ? "1.25rem" : "1.5rem",
                    border: "none",
                    borderRadius: "2rem",
                    background: "#E6DFFE",
                    height: "4rem",
                  }}
                >
                  Đăng nhập
                </button>
                <ButtonRegister
                  onClick={() => (window.location.href = "/register")}
                />
              </div>
            </header>
            <section
              id="tablet-header"
              className={`${
                isHeaderFixed === null
                  ? "displayNone"
                  : isHeaderFixed
                  ? "fixedHeader"
                  : "remote-fixed-header"
              } has-background-kurio-main-color-white`}
              style={{ width: "100%", zIndex: 9999, top: "-0.125rem" }}
            >
              <header
                style={{
                  width: "100%",
                  height: "6rem",
                  padding: "0rem 2.5rem",
                }}
                className={
                  "is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
                }
              >
                <img
                  style={{ width: "9.27144rem", height: "3rem" }}
                  src="/images/landing-page/logo.svg"
                  alt=""
                />
                <div
                  className="is-flex is-align-items-center is-justify-content-center"
                  style={{ gap: "10px" }}
                >
                  <button
                    onClick={() => {
                      window.location.href = "/signin";
                    }}
                    className=" has-text-weight-bold is-clickable is-uppercase"
                    style={{
                      color: "#8864F0",
                      padding: windowWidth <= 820 ? "0.5rem 1rem" : "1rem 2rem",
                      fontSize: windowWidth <= 820 ? "1.25rem" : "1.5rem",
                      border: "none",
                      borderRadius: "2rem",
                      background: "#E6DFFE",
                      height: "4rem",
                    }}
                  >
                    Đăng nhập
                  </button>
                  <ButtonRegister onClick={handleScrollToForm} />
                </div>
              </header>
            </section>
            <div id="tablet-banner" style={{ width: "100%" }}>
              <LandingPageBanner
                isTablet={true}
                onClick={handleScrollToForm}
                handleDownloadApp={handleDownloadApp}
              />
            </div>
            <LandingPageIntro isTablet={true} />
            <LandingPageFeature isTablet={true} />
            <LandingPageUserReview
              onClick={handleScrollToForm}
              isTablet={true}
            />
            <LandingPageQA isTablet={true} />

            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{
                background: "#F1EEFC",
                width: "100%",
                backgroundImage:
                  "url(/images/landing-page/background-uu-dai.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "61.375rem",
                zIndex: 9999,
              }}
            >
              <div
                className="is-flex is-justify-content-flex-end is-align-items-flex-end"
                style={{ width: "36.875rem" }}
              >
                <img
                  className="pt-3"
                  src="/images/landing-page/desktop-uu-dai.png"
                  alt=""
                  style={{ width: "22.9535rem", objectFit: "contain" }}
                />
                <img
                  style={{
                    width: "16rem",
                    height: "13.6875rem",
                    marginLeft: "-2rem",
                  }}
                  src="/images/landing-page/image-landing-page-3.png"
                  alt=""
                />
              </div>

              <div id="form-register">
                <LandingPageForm isTablet={true} />
              </div>
            </div>
            <section
              id="tablet-footer"
              style={{ background: colorWhite, width: "100%" }}
            >
              <footer
                className="is-flex is-flex-direction-column is-align-items-start"
                style={{ gap: "4rem", padding: "4rem" }}
              >
                <div
                  className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  style={{ gap: "1.5rem" }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "1.25rem",
                      color: colorBlack,
                      textTransform: "uppercase",
                    }}
                  >
                    Liên hệ
                  </p>
                  <div
                    className="is-flex is-flex-direction-column"
                    style={{ gap: "1rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
                    </p>

                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        color: colorBlack,
                        lineHeight: "1.125rem",
                      }}
                    >
                      Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế
                      hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.
                    </span>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "1rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-gmail.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "1rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        contact@ikemso.com
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-phone.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        0971 500 120
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-zalo.png" alt="" />
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        0971 500 120
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-flex-start"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-address.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "1rem",
                          color: colorBlack,
                          lineHeight: "1.5rem",
                        }}
                      >
                        Số 128, phố Nguyễn Thái Học, <br />
                        phường Điện Biên, quận Ba Đình, Hà Nội
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="is-flex is-flex-direction-row is-justify-content-center is-align-items-flex-start"
                  style={{ gap: "4rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                    style={{ gap: "1.5rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1.25rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      Chính sách
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1rem" }}
                    >
                      <Link to={"/payment-methods"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Hình thức thanh toán
                        </p>
                      </Link>
                      <Link to={"/return-policy"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Vận chuyển - Trả hàng hoàn tiền
                        </p>
                      </Link>
                      <Link to={"/privacy-policy"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Chính sách bảo mật
                        </p>
                      </Link>
                    </div>

                    <Link to={"/payment-policy"}>
                      <div
                        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                        style={{
                          gap: "0.75rem",
                          width: "15rem",
                          height: "3.5rem",
                          borderRadius: "1rem",
                          border: "1px solid #0A2A66",
                          background: colorWhite,
                          boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px",
                        }}
                      >
                        <img
                          style={{ width: "6.25rem", height: "1.89581rem" }}
                          src="/images/landing-page/image-vnpay.png"
                          alt=""
                        />
                        <p
                          style={{
                            width: "5.125rem",
                            fontSize: "0.875rem",
                            fontWeight: 700,
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Chính sách thanh toán
                        </p>
                      </div>
                    </Link>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "3rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1.5rem" }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1.25rem",
                          color: colorBlack,
                          textTransform: "uppercase",
                        }}
                      >
                        Ứng dụng
                      </p>

                      <div
                        className="is-flex is-flex-direction-column is-align-items-flex-start"
                        style={{ gap: "1rem" }}
                      >
                        <Link
                          to={
                            "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
                          }
                        >
                          <img
                            src="/images/landing-page/app-store.png"
                            alt=""
                          />
                        </Link>
                        <Link
                          to={
                            "https://play.google.com/store/apps/details?id=vn.kurio.android"
                          }
                        >
                          <img src="/images/landing-page/gg-play.png" alt="" />
                        </Link>
                      </div>
                    </div>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "1.5rem" }}
                    >
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "1.25rem",
                          color: colorBlack,
                          textTransform: "uppercase",
                        }}
                      >
                        Mạng xã hội
                      </p>

                      <div
                        className="is-flex is-flex-direction-column is-align-items-flex-start"
                        style={{ gap: "1rem" }}
                      >
                        <Link to={"https://www.facebook.com/kurio.vn"}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: "1rem",
                              color: colorBlack,
                              lineHeight: "normal",
                            }}
                          >
                            Facebook
                          </p>
                        </Link>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Youtube
                        </p>
                        <Link to={"https://zalo.me/0971500120"}>
                          <p
                            style={{
                              fontWeight: 500,
                              fontSize: "1rem",
                              color: colorBlack,
                              lineHeight: "normal",
                            }}
                          >
                            Zalo
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ width: "100%" }}
                  className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
                >
                  <Link
                    to={
                      "http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1"
                    }
                  >
                    <img
                      style={{ width: "12.5rem", height: "4.75113rem" }}
                      src="/images/landing-page/image-bct.png"
                      alt=""
                    />
                  </Link>
                  <div
                    className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                    style={{ gap: "1.5rem" }}
                  >
                    <img
                      onClick={() => backToTop()}
                      style={{
                        width: "5rem",
                        height: "5rem",
                        cursor: "pointer",
                      }}
                      src="/images/landing-page/icon-back-to-top.svg"
                      alt=""
                    />

                    <p
                      onClick={() => backToTop()}
                      style={{
                        fontSize: "1.25rem",
                        color: colorBlack,
                        cursor: "pointer",
                        textTransform: "uppercase",
                        fontWeight: 700,
                        lineHeight: "normal",
                        width: "6rem",
                      }}
                    >
                      Về đầu trang
                    </p>
                  </div>
                </div>
              </footer>
            </section>
          </div>
        )}

        {/* Mobile landing page */}
        {windowWidth < 768 && (
          <div
            className="container-landingpage has-background-kurio-main-color-white"
            id="mobile-landingpage"
          >
            <header
              style={{ width: "100%", height: "5rem", padding: "0rem 1.5rem" }}
              className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
            >
              <img
                style={{ objectFit: "cover", height: "2.75rem" }}
                src="/images/landing-page/logo.svg"
                alt=""
              />
              <ButtonRegister
                onClick={handleDownloadApp}
                isReverse={true}
                text="Tải App ngay"
                height="3.75rem"
                width="9.6875rem"
                fontSize="1rem"
              />
            </header>

            <section
              id="mobile-header"
              className={
                isHeaderFixedMobile === null
                  ? "displayNone"
                  : isHeaderFixedMobile
                  ? "fixedHeader"
                  : "remote-fixed-header"
              }
              style={{
                background: colorWhite,
                width: "100%",
                position: "fixed",
                top: "-0.125rem",
                zIndex: 9999,
              }}
            >
              <header
                style={{
                  width: "100%",
                  height: "5rem",
                  padding: "0rem 1.5rem",
                }}
                className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
              >
                <img
                  style={{ objectFit: "cover", height: "2.75rem" }}
                  src="/images/landing-page/logo.svg"
                  alt=""
                />
                <ButtonRegister
                  isReverse={true}
                  onClick={handleDownloadApp}
                  text="Tải App ngay"
                  height="3.75rem"
                  width="9.6875rem"
                  fontSize="1rem"
                />
              </header>
            </section>
            <div
              id="mobile-banner"
              ref={scrollableDivRef}
              style={{ width: "100%" }}
            >
              <LandingPageBanner
                isMobile={true}
                onClick={handleScrollToForm}
                handleDownloadApp={handleDownloadApp}
              />
            </div>
            <LandingPageIntro isMobile={true} />
            <LandingPageFeature isMobile={true} />
            <LandingPageUserReview
              isMobile={true}
              onClick={handleScrollToForm}
            />
            <LandingPageQA isMobile={true} />
            <div
              className="is-flex is-flex-direction-column is-flex-gap-4 is-align-items-center is-justify-content-center"
              style={{
                background: "#F1EEFC",
                width: "100%",
                backgroundImage:
                  "url(/images/landing-page/background-uu-dai.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "50rem",
                // zIndex: 99,
              }}
            >
              <img
                className="pt-3"
                src="/images/landing-page/desktop-uu-dai.png"
                alt=""
                style={{ width: "20.5625rem" }}
              />
              <div id="form-register">
                <LandingPageForm isMobile={true} />
              </div>
            </div>
            <section
              id="mobile-footer"
              style={{
                background: colorWhite,
                width: "100%",
                marginBottom: "10%",
              }}
            >
              <footer
                className="is-flex is-flex-direction-column is-align-items-start"
                style={{ gap: "3rem", padding: "4rem 2rem" }}
              >
                <div
                  className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  style={{ gap: "1rem" }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: colorBlack,
                      textTransform: "uppercase",
                    }}
                  >
                    Liên hệ
                  </p>
                  <div
                    className="is-flex is-flex-direction-column"
                    style={{ gap: "0.5rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      CÔNG TY CỔ PHẦN IKEMSO TOÀN CẦU
                    </p>

                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        color: colorBlack,
                        lineHeight: "1.125rem",
                      }}
                    >
                      Giấy chứng nhận Đăng ký Kinh doanh số 0109501325 do Sở Kế
                      hoạch và Đầu tư Thành phố Hà Nội cấp ngày 19/01/2021.
                    </span>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "0.75rem" }}
                  >
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-gmail.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        contact@ikemso.com
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-phone.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        {/* 1900 8157 */}
                        0971 500 120
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-zalo.png" alt="" />
                      <p
                        style={{
                          fontWeight: 700,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        0971 500 120
                      </p>
                    </div>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center"
                      style={{ gap: "1rem" }}
                    >
                      <img src="/images/landing-page/icon-address.svg" alt="" />
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "1.125rem",
                        }}
                      >
                        Số 128, phố Nguyễn Thái Học, phường Điện Biên, quận Ba
                        Đình, Hà Nội
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start"
                  style={{ gap: "1rem" }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: "1rem",
                      color: colorBlack,
                      textTransform: "uppercase",
                    }}
                  >
                    Chính sách
                  </p>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "0.5rem" }}
                  >
                    <Link to={"/payment-methods"}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        Hình thức thanh toán
                      </p>
                    </Link>
                    <Link to={"/return-policy"}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        Vận chuyển - Trả hàng hoàn tiền
                      </p>
                    </Link>
                    <Link to={"/privacy-policy"}>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        Chính sách bảo mật
                      </p>
                    </Link>
                  </div>

                  <Link to={"/payment-policy"}>
                    <div
                      className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                      style={{
                        gap: "0.75rem",
                        width: "15rem",
                        height: "3.5rem",
                        borderRadius: "1rem",
                        border: "1px solid #0A2A66",
                        background: colorWhite,
                        boxShadow: "rgb(10, 42, 102) -2px 2px 0px 1px",
                      }}
                    >
                      <img
                        style={{ width: "6.25rem", height: "1.89581rem" }}
                        src="/images/landing-page/image-vnpay.png"
                        alt=""
                      />
                      <p
                        style={{
                          width: "5.125rem",
                          fontSize: "0.875rem",
                          fontWeight: 700,
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        Chính sách thanh toán
                      </p>
                    </div>
                  </Link>
                </div>

                <div
                  className="is-flex is-flex-direction-column is-align-items-flex-start"
                  style={{ gap: "3rem" }}
                >
                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "1rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      Ứng dụng
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "0.5rem" }}
                    >
                      <Link
                        to={
                          "https://apps.apple.com/us/app/to%C3%A1n-kurio/id6478091573"
                        }
                      >
                        <img src="/images/landing-page/app-store.png" alt="" />
                      </Link>
                      <Link
                        to={
                          "https://play.google.com/store/apps/details?id=vn.kurio.android"
                        }
                      >
                        <img src="/images/landing-page/gg-play.png" alt="" />
                      </Link>
                    </div>
                  </div>

                  <div
                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                    style={{ gap: "1rem" }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        color: colorBlack,
                        textTransform: "uppercase",
                      }}
                    >
                      Mạng xã hội
                    </p>

                    <div
                      className="is-flex is-flex-direction-column is-align-items-flex-start"
                      style={{ gap: "0.5rem" }}
                    >
                      <Link to={"https://www.facebook.com/kurio.vn"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Facebook
                        </p>
                      </Link>
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          color: colorBlack,
                          lineHeight: "normal",
                        }}
                      >
                        Youtube
                      </p>
                      <Link to={"https://zalo.me/0971500120"}>
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            color: colorBlack,
                            lineHeight: "normal",
                          }}
                        >
                          Zalo
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className="is-flex is-flex-direction-row is-align-items-center"
                  style={{ gap: "4rem" }}
                >
                  <Link
                    to={
                      "http://online.gov.vn/Home/WebDetails/110320?AspxAutoDetectCookieSupport=1"
                    }
                  >
                    <img
                      style={{ width: "6.90625rem", height: "2.625rem" }}
                      src="/images/landing-page/image-bct.png"
                      alt=""
                    />
                  </Link>
                  <div
                    className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center"
                    style={{ gap: "1rem" }}
                  >
                    <img
                      onClick={() => backToTop()}
                      style={{
                        zIndex: 2,
                        width: "2.5rem",
                        height: "2.5rem",
                        cursor: "pointer",
                      }}
                      src="/images/landing-page/icon-back-to-top.svg"
                      alt=""
                    />

                    <p
                      onClick={() => backToTop()}
                      style={{
                        zIndex: 2,
                        fontSize: "0.875rem",
                        color: colorBlack,
                        cursor: "pointer",
                        textTransform: "uppercase",
                        fontWeight: 700,
                        lineHeight: "normal",
                        width: "3.75rem",
                      }}
                    >
                      Về đầu trang
                    </p>
                  </div>
                </div>
              </footer>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export default ResizeLandingPage;
