/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import ExerciseCloseButton from "../../components/button/special-button/ExerciseCloseButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import { submitPracticeAnswers } from "../../config/practice";
import { submitPracticeTopicAnswer } from "../../config/practiceTopic";
import {
  setPracticeImage,
  setQuestionState,
} from "../../reducers/practiceElectiveSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import { chunk } from "../../templates/helper/splitArray";
import PracticeContent from "./PracticeContent";
import PracticeNav from "./PracticeNav";
import { checkPremium } from "../../templates/helper/CheckAndGetAnonymousUserId";

function PracticeElectiveMainWrapper({
  questions,
  seenQuestions,
  correctAnswers,
  handleEndPractice,
  practiceQuestionListId,
  practiceImages,
  IKMCMode,
  userInfo,
}) {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [showModal, setShowModal] = useState("");
  const [endPracticeState, setEndPractice] = useState(false);
  const [exitState, setExitState] = useState(false);
  const [images, setImages] = useState([]);
  const commonStyle = {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
  };
  const dispatch = useDispatch();

  const closeModal = () => {
    setShowModal("");
    const answer = JSON.parse(localStorage.getItem("exerciseAnswer"));
    if (answer) {
      submitAnswers(answer);
    }
    localStorage.removeItem("exerciseAnswer");
    setSelectedQuestion(0);
    if (handleEndPractice) {
      handleEndPractice();
    }
  };
  const showEndPractice = () => {
    const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
    if (exerciseState) {
      // if (exerciseState.questionState.state.checkingState) {
      //   submitAnswers(exerciseState.answer);
      // } else {
      //   submitAnswers(null);
      // }
      dispatch(setQuestionState(exerciseState.questionState));
      // if (
      //   exerciseState.correct !== null &&
      //   exerciseState.correct !== undefined
      // ) {
      //   if (exerciseState.correct === true) {
      //     dispatch(setCorrectAnswer(1));
      //     dispatch(
      //       setCorrectQuestion({
      //         questionIndex: exerciseState.questionState.index,
      //         correct: true,
      //       })
      //     );
      //   } else {
      //     dispatch(
      //       setCorrectQuestion({
      //         questionIndex: exerciseState.questionState.index,
      //         correct: false,
      //       })
      //     );
      //     dispatch(setWrongAnswer(1));
      //   }
      // }
    }
    localStorage.removeItem("exerciseState");
    setEndPractice(true);
  };
  const listIndexDisplayLoadingImage = [0, 10, 20, 30, 40];
  useEffect(() => {
    // dispatch(fetchPracticeElective());
    if (questions) {
      setShowModal("is-active");
      const questionsChunk = chunk(questions, 10);
      var splitArrayImage = [];
      questionsChunk.map((item) => {
        const result = getAllImage(item, "PRACTICE");
        splitArrayImage.push(result);
        return true;
      });
      if (practiceImages === null) {
        dispatch(setPracticeImage(splitArrayImage));
      }
    }
    if (practiceImages !== null) {
      const listIndexLoadingImage = [0, 9, 19, 29, 39];
      const check = listIndexLoadingImage.includes(selectedQuestion);
      if (check) {
        setImages([
          ...images,
          practiceImages[listIndexLoadingImage.indexOf(selectedQuestion)],
        ]);
      }
    }
  }, [dispatch, practiceImages, questions, selectedQuestion]);

  const handleQuestionChange = (val) => {
    // const exerciseState = JSON.parse(localStorage.getItem("exerciseState"));
    // if (exerciseState) {
    //   if (exerciseState.questionState.state.checkingState) {
    //     submitAnswers(exerciseState.answer);
    //   } else {
    //     submitAnswers(null);
    //   }
    //   dispatch(setQuestionState(exerciseState.questionState));
    //   if (
    //     exerciseState.correct !== null &&
    //     exerciseState.correct !== undefined
    //   ) {
    //     if (exerciseState.correct === true) {
    //       dispatch(setCorrectAnswer(1));
    //       dispatch(
    //         setCorrectQuestion({
    //           questionIndex: exerciseState.questionState.index,
    //           correct: true,
    //         })
    //       );
    //     } else {
    //       dispatch(
    //         setCorrectQuestion({
    //           questionIndex: exerciseState.questionState.index,
    //           correct: false,
    //         })
    //       );
    //       dispatch(setWrongAnswer(1));
    //     }
    //   }
    // }
    localStorage.removeItem("exerciseState");
    setSelectedQuestion(val);
  };
  const submitAnswers = async (answer) => {
    var answers = [
      {
        answer: answer,
        questionId: questions[selectedQuestion].questionId,
      },
    ];
    try {
      if (IKMCMode) {
        await submitPracticeTopicAnswer(practiceQuestionListId, answers);
      } else {
        await submitPracticeAnswers(practiceQuestionListId, answers);
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  // const createElective = () => {
  //   setShowModal("is-active");
  // };
  return (
    <div>
      {/* <button className="button" onClick={createElective}>
        Create Elective
      </button> */}
      {listIndexDisplayLoadingImage.includes(selectedQuestion) &&
        images?.length !== 0 && (
          <LoadingImage
            images={
              images[listIndexDisplayLoadingImage.indexOf(selectedQuestion)]
            }
            type={"PRACTICE"}
          />
        )}
      {questions && (
        <div
          // objectId="admin/frontend-images/exercise/exercise-bg.svg"
          className={`modal ${
            showModal.length > 0 ? `${showModal} ` : ""
          } p-0 `}
          style={{
            backgroundSize: "contain",
            zIndex: "200",
            backgroundImage:
              "url('/images/hub/background-popup-daily-mission.svg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
          }}
        >
          <div
            // objectId="admin/frontend-images/exercise/exercise-bg.svg"
            className="modal-background"
            style={{
              minHeight: "100%",
              backgroundImage:
                "url('/images/hub/background-popup-daily-mission.svg')",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
              opacity: "80%",
            }}
          ></div>
          {!exitState && (
            <BackgroundImageFromUrl
              className=" is-relative"
              objectId={
                IKMCMode
                  ? "admin/frontend-images/practice-ikmc/practice-ikmc-question-bg.svg"
                  : "admin/frontend-images/exercise/exercise-question-bg.svg"
              }
              style={{
                ...commonStyle,
                width: "75rem",
                height: "calc(100% - 2.5rem)",
                background: "#EFEFEF",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "7.5rem 7.5rem 0 0",
                padding: "3rem 3.13rem 3.31rem 3.12rem",
                top: "2.5rem",
                zIndex: "200",
              }}
            >
              <div
                className={`${
                  IKMCMode
                    ? "has-background-kurio-main-color-white"
                    : "has-background-kurio-purple-light-3"
                } is-justify-content-center`}
                style={{
                  ...commonStyle,
                  width: "100%",
                  height: "100%",
                  borderRadius: "5rem 5rem 2.5rem 2.5rem",
                  border: IKMCMode ? "4px solid #BCD9F1" : "4px solid #290E76",
                  padding: "0 4.88rem 0 4.88rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-1rem",
                    zIndex: 1000,
                    left: "-2rem",
                  }}
                >
                  <ExerciseCloseButton onClick={() => setExitState(true)} />
                </div>
                <BackgroundImageFromUrl
                  objectId={
                    IKMCMode
                      ? ""
                      : "admin/frontend-images/exercise/exercise-score-small.svg"
                  }
                  style={{
                    ...commonStyle,
                    position: "absolute",
                    justifyContent: "center",
                    top: "-1.5rem",
                    right: "-1rem",
                    width: "9rem",
                    height: "9rem",
                    zIndex: "100",
                    backgroundImage: IKMCMode
                      ? "url('/images/practice-ikmc/ikmc-score-small.svg')"
                      : "",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p
                    className="has-text-weight-bold mt-4"
                    style={{ fontSize: "1.75rem" }}
                  >
                    <span className="mr-2 has-text-kurio-purple-dark">
                      {correctAnswers}
                    </span>
                    <span className="has-text-kurio-purple-light-0">/</span>
                    <span className="ml-2 has-text-kurio-purple-light-0">
                      {seenQuestions}
                    </span>
                  </p>
                </BackgroundImageFromUrl>
                <div
                  style={{
                    position: "fixed",
                    zIndex: "100",
                    top: "0.5rem",
                  }}
                >
                  {IKMCMode ? (
                    <img
                      src="/images/practice-ikmc/practice-ikmc-nav.svg"
                      alt="ikmc"
                    />
                  ) : (
                    <ImageFromUrl
                      objectId="admin/frontend-images/exercise/exercise-update-nav-update.svg"
                      alt="exercise"
                    />
                  )}
                </div>
                <PracticeNav
                  selected={selectedQuestion}
                  IKMCMode={IKMCMode}
                  handleQuestionChange={handleQuestionChange}
                />
                {endPracticeState && (
                  <>
                    {((questions?.length === 50 &&
                      checkPremium({
                        userInfo: userInfo,
                      })) ||
                      (IKMCMode && questions?.length === 30)) && (
                      <>
                        <div
                          className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center has-text-kurio-main-color-black m-auto"
                          style={{
                            gap:
                              questions?.length === 50 || IKMCMode
                                ? ""
                                : "4.75rem",
                            width: "100%",
                            padding: "4rem 3rem",
                          }}
                        >
                          <div
                            style={{ gap: "1.5rem", width: "15rem" }}
                            className="is-flex is-flex-direction-column is-is-align-items-flex-start"
                          >
                            <p className="is-size-1 has-text-weight-bold">
                              Uh, oh~
                            </p>
                            <p
                              style={{
                                fontSize: "1.125rem",
                                lineHeight: "normal",
                              }}
                            >
                              Nghỉ ngơi chút nào! Bạn đã làm tới{" "}
                              {questions?.length} câu rồi!
                            </p>
                            <PrimaryButtonPageLevel
                              onClick={() => setExitState(true)}
                              style={{ height: "4rem", borderRadius: "1rem" }}
                            >
                              Kết thúc
                            </PrimaryButtonPageLevel>
                          </div>
                          <div>
                            <ImageFromUrl
                              objectId="admin/frontend-images/exercise/end-practice-bg-update.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {((!IKMCMode && questions?.length < 50) ||
                      !checkPremium({ userInfo: userInfo })(
                        IKMCMode && questions?.length < 30
                      )) && (
                      <>
                        <div
                          className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center has-text-kurio-main-color-black m-auto"
                          style={{
                            gap: questions?.length === 50 ? "" : "4.75rem",
                            width: "100%",
                            padding: "7.5rem 1rem",
                          }}
                        >
                          <div
                            style={{ gap: "1.5rem", width: "21.25rem" }}
                            className="is-flex is-flex-direction-column is-is-align-items-flex-start"
                          >
                            <p
                              style={{ fontSize: "3rem" }}
                              className="has-text-weight-bold"
                            >
                              Chúc mừng!
                            </p>
                            <p
                              style={{
                                fontSize: "1.125rem",
                                width: "15rem",
                                lineHeight: "normal",
                              }}
                            >
                              Bạn đã hoàn thành toàn bộ số bài tập hiện có của
                              phần đã chọn.
                            </p>
                            <PrimaryButtonPageLevel
                              onClick={() => setExitState(true)}
                              style={{ height: "4rem", borderRadius: "1rem" }}
                            >
                              Kết thúc
                            </PrimaryButtonPageLevel>
                          </div>
                          <ImageFromUrl
                            objectId="admin/frontend-images/exercise/end-practice-less-than-50-questions-bg.svg"
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {!endPracticeState && !exitState && (
                  <PracticeContent
                    question={questions[selectedQuestion]}
                    questions={questions}
                    seenQuestions={seenQuestions}
                    handleQuestionChange={handleQuestionChange}
                    closeModal={closeModal}
                    showEndPractice={showEndPractice}
                    submitAnswers={submitAnswers}
                  />
                )}
              </div>
            </BackgroundImageFromUrl>
          )}
          {exitState && (
            <BackgroundImageFromUrl
              className=" is-relative"
              objectId={
                IKMCMode
                  ? "admin/frontend-images/practice-ikmc/practice-ikmc-question-bg.svg"
                  : "admin/frontend-images/exercise/exercise-question-bg.svg"
              }
              style={{
                ...commonStyle,
                width: "75rem",
                height: "calc(100% - 2.5rem)",
                background: "#EFEFEF",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: "7.5rem 7.5rem 0 0",
                padding: "3rem 3.13rem 3.31rem 3.12rem",
                top: "2.5rem",
                zIndex: "2",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "-1rem",
                  zIndex: 1000,
                  left: "-2rem",
                }}
              >
                <ExerciseCloseButton onClick={closeModal} />
              </div>
              <BackgroundImageFromUrl
                objectId={
                  IKMCMode
                    ? "admin/frontend-images/practice-ikmc/practice-ikmc-exit.svg"
                    : "admin/frontend-images/exercise/exercise-exit-bg-update.svg"
                }
                className="is-relative"
                style={{
                  ...commonStyle,
                  width: "38rem",
                  height: "32.25rem",
                  top: "6.8rem",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <BackgroundImageFromUrl
                  objectId={
                    IKMCMode
                      ? ""
                      : "admin/frontend-images/exercise/exercise-score-big.svg"
                  }
                  style={{
                    ...commonStyle,
                    justifyContent: "center",
                    position: "absolute",
                    top: "-4.5rem",
                    // left: "13.1875rem",
                    backgroundImage: IKMCMode
                      ? "url('/images/practice-ikmc/ikmc-score-big.svg')"
                      : "",
                    width: "11.25rem",
                    height: "11.25rem",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <p
                    className="has-text-weight-bold mt-5"
                    style={{ fontSize: "1.75rem" }}
                  >
                    <span className="mr-2 has-text-kurio-purple-dark">
                      {correctAnswers}
                    </span>
                    <span className="has-text-kurio-purple-light-0">/</span>
                    <span className="ml-2 has-text-kurio-purple-light-0">
                      {seenQuestions}
                    </span>
                  </p>
                </BackgroundImageFromUrl>
                <PrimaryButtonPageLevel
                  onClick={handleEndPractice}
                  style={{
                    height: "4rem",
                    top: "23.5rem",
                    position: "absolute",
                    borderRadius: "1rem",
                  }}
                >
                  Thoát
                </PrimaryButtonPageLevel>
              </BackgroundImageFromUrl>
            </BackgroundImageFromUrl>
            // </BackgroundImageFromUrl>
          )}
        </div>
      )}
      {questions === null && (
        <div className="has-text-center has-text-kurio-main-color-black has-text-weight-bold">
          Loading...
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
    practiceImages: state.practiceElective.practiceImages,
    seenQuestions: state.practiceElective.seenQuestions,
    correctAnswers: state.practiceElective.correctAnswers,
    userInfo: state.grades.listUserInfo,
  };
}

export default connect(mapStateToProps)(PracticeElectiveMainWrapper);
