import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import MenuLeft from "./MenuLeft";
import MenuTopBar from "./MenuTopBar";
import ConstantStrings from "../../constants/ConstantStrings";

const MenuMainWrapper = ({ image, handleChangePathName, pathname }) => {
  const [widthScreen, setWidthScreen] = React.useState(window.innerWidth/ ConstantStrings.BASE_REM);
  useEffect(() => {
    const setWidth = () => {
      setWidthScreen(window.innerWidth / ConstantStrings.BASE_REM);
    };
    if (window.innerWidth / ConstantStrings.BASE_REM !== widthScreen) {
      setWidthScreen(window.innerWidth / ConstantStrings.BASE_REM);
    }
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [widthScreen]);
  return (
    <div
      className="is-flex is-flex-direction-column is-justify-content-center m-auto wrapper-main-content"
      style={{ maxWidth: "80rem", minHeight: "100vh" }}
    >
      <div className="columns is-mobile m-0 p-0" style={{ minHeight: "100vh" }}>
        {widthScreen >= 64 && (
          <div
            className="column p-0 m-0 is-one-fifth"
            style={{ maxWidth: "13.5rem" }}
          >
            <MenuLeft />
          </div>
        )}

        <div className="column p-0 m-0 " style={{ maxWidth: "66.5rem" }}>
          <MenuTopBar
            handleChangePathName={(pathname) => handleChangePathName(pathname)}
            image={image}
            pathname={pathname}
            isMobile={widthScreen < 64}
          />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    questions: state.practiceElective.questions,
    seenQuestions: state.practiceElective.seenQuestions,
    correctAnswers: state.practiceElective.correctAnswers,
  };
}

export default connect(mapStateToProps)(MenuMainWrapper);
